import { Progress } from "antd";
import { getRadialColor } from "../../Utils/CommonFunctions";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { handleProductCardClick } from "../../Gtag";
import placeHolderImg from "../../Assets/Images/placeholder.png";
import './index.scss'

const ProductCard = ({ carousel, product }) => {

  const url = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
  }

  return (
    <a href={`/p/${product?.productNameURL ? product?.productNameURL : url(product['Retailer Product Name'])}`}>
      <div className="product-card card" onClick={() => handleProductCardClick(carousel === "similar" ? 'Product pairings' : 'Better-for-you', product['Retailer Product Name'])}>
        <Progress
          type="circle"
          percent={Math.round(product?.totalGs)}
          size={42}
          className="fw-bold text-primary pt-1"
          strokeWidth="11"
          strokeLinecap="round"
          format={(percent) => percent + ""}
          strokeColor={`${getRadialColor(Math.round(product?.totalGs))}`}
        />
        <img className="product-img" src={product?.mainProductImage === "" || product?.mainProductImage === null || product?.mainProductImage === null ? placeHolderImg : product?.mainProductImage} alt={product?.productName} />
        <p className="product-title">{product['Retailer Product Name']?.toLowerCase()}</p>
      </div>
    </a>
  );
};

export default ProductCard;
